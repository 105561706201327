<template>
  <div>
    <b-tabs>
      <b-tab title="Application Documents">
        <student-documents />
      </b-tab>
      <b-tab title="Visa Documents">
        <student-visa-documents />
      </b-tab>
      <!-- <b-tab title="Sample Documents">
        <sample-documents />
      </b-tab> -->
      <b-tab title="Documents uploaded by Away Education">
        <staff-document />
      </b-tab>
      <!-- <b-tab title="Visa Checklist">
        <visa-checklist />
      </b-tab> -->
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import StudentDocuments from "./StudentDocuments.vue";
import StudentVisaDocuments from "./StudentVisaDocuments.vue";
import StaffDocument from "./StaffDocuments.vue";
// import SampleDocuments from "./SampleDocuments.vue";
// import VisaChecklist from "./VisaCheckList.vue";

export default {
  components: {
    BTabs,
    BTab,
    StudentDocuments,
    StudentVisaDocuments,
    StaffDocument,
    // SampleDocuments,
    // VisaChecklist,
  },
  // directives: {
  //   Ripple,
  // },
  data() {
    return {};
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
